.collection-container {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 5em;
}

.collection-container .heading {
  font-size: 2.2em;
  font-weight: bold;
}

.collection-description,
.more-description, .more-more-description {
  margin-top: 1.8em;
  font-size: 1.2em;
}

.images-collection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4em;
}

.collection-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2.8em;
}

.image-container img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .collection-container {
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5em;
  }

  .collection-container .heading {
    font-size: 2.6em;
    font-weight: bold;
  }

  .images-collection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4em;
  }

  .collection-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3em !important;
  }
}

@media screen and (min-width:600px) and (max-width:920px) {
  .collection-container {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5em;
  }

}