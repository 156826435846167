.footer {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 5em;
}

.footer-head {
  font-size: 3em;
  font-weight: bold;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3em;
}

.socials {
  margin-top: 3em;
  display: flex;
  align-items: center;
  gap: 3em;
}

.link {
  font-size: 4em;
  padding: 10px;
  transition: all 0.4s;
}

.link:hover {
  border: 1px solid white;
  border-radius: 50px;
  background-color: rgba(211, 211, 211, 0.342);
  transform: scale(1.4);
}

.footer-container .email {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-top: 2em;
}

.email p,
.email a {
  font-size: 1.4em;
}

.email a {
  color: gold;
}

.contract-add {
  margin-top: 3em;
  display: flex;
  align-self: center;
  gap: 10px;
}

.contract-add span {
  text-transform: uppercase;
  color: gold;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  transition: all 0.4s;
}

.contract-add span:hover {
  color: rgb(180, 2, 180);
}

.copyright {
  margin-top: 10em;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .footer {
    max-width: 380px;
  }

  .footer-head {
    font-size: 2em;
    font-weight: bold;
  }

  .footer-container {
    margin-top: 2em;
  }

  .socials {
    gap: 1.5em !important;
  }

  .link {
    font-size: 3em;
    padding: 10px;
  }

  .email {
    text-align: center;
  }

  .email p,
  .email a {
    font-size: 1em;
  }

  .contract-add {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 13px;
    text-align: center;
  }

  .copyright {
    margin-top: 10em;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .footer {
    max-width: 750px;
  }

  .footer-head {
    font-size: 2.5em;
    font-weight: bold;
  }

  .footer-container {
    margin-top: 2em;
  }

  .socials {
    gap: 1.6em !important;
  }

  .link {
    font-size: 3.5em;
    padding: 10px;
  }

  .email p,
  .email a {
    font-size: 1.5em;
  }

  .contract-add {
    font-size: 16px;
    text-align: center;
  }

  .copyright {
    margin-top: 10em;
  }
}
