.mintsection {
  margin-top: 4.5em;
  background: url(../Asset/banner.jpg);
  background-position: center;
  background-size: cover;
}

.mint-container {
  background-color: orchid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  max-width: 850px;
  margin: auto;
  padding: 3em 3em;
  margin-top: 3em;
  border-radius: 15px;
  box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
}
.closed {
  text-decoration: line-through;
}
.welcome {
  display: flex;
  flex-direction: column;
  max-width: 440px;
  margin: auto;
  text-align: center;
  line-height: 1.1em;
  font-weight: bold;
  color: rgb(213, 213, 224);
}

.welcome .head {
  font-size: 1.5em;
  line-height: 1.4em;
  margin-bottom: 0.6em;
  color: purple;
}

.head-2 {
  color: royalblue;
}

.head-2 .fes {
  color: royalblue;
}

.head-2 .sec {
  color: gold;
}

.gif {
  max-width: 300px;
  margin: auto;
}

.butt-val {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.butt-val button {
  padding: 0.9em 2em;
  border-radius: 50px;
  border: none;
  background-color: rgb(83, 83, 83);
  color: rgb(182, 241, 63);
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

input[type="number"] {
  position: relative;
  color: rgb(110, 110, 110);
  font-size: 25px;
  font-weight: bold;
}

.reminants {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.7em;
  font-weight: bolder;
  color: greenyellow;
}

.reminants p {
  color: rgb(110, 110, 110);
}

.contract-details {
  max-width: 850px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -1.5em;
}

.address {
  display: flex;
  align-items: center;
  gap: 5px;
}

.address {
  font-weight: bold;
  font-size: 17px;
}

.address .blue a {
  color: rgb(169, 241, 24);
  font-weight: bold;
  font-size: 17px;
}

.details {
  text-align: center;
  line-height: 1.6em;
  margin-top: 2em;
}

.orange {
  color: orangered;
}

.mint-infos {
  font-weight: bold;
  color: lightgray;
  margin-top: 2em;
  text-align: center;
}

.tokenmint .explorer {
  font-size: 14px;
  margin-top: 15px;
  color: white;
  background-color: purple;
  border-radius: 20px;
  padding: 0.5em;
  font-weight: 700;
}

.tokenmint .explorer a {
  color: gold;
}

.tokenmint .explorer a:hover {
  color: white;
}

.mosdal {
  background-color: aliceblue;
  padding: 0.6em;
  border-radius: 1em;
  border-color: gold;
  border-style: double;
}

.modal-contents .head {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-contents .head p {
  font-size: large;
  padding-left: 1em;
  cursor: pointer;
}
@media screen and (min-width: 350px) and (max-width: 600px) {
  .mint-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    max-width: 80vw;
    margin: auto;
    padding: 3em 2em;
    margin-top: 3em;
    border-radius: 15px;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
  }

  .welcome {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 440px;
    margin: auto;
    line-height: 1.7em;
  }

  .head-2 {
    font-size: 1em;
  }

  .gif {
    max-width: 70vw;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mintbutton-value {
    display: flex;
  }

  .contract-details {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.3em;
  }

  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .reminants {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -0.7em;
    font-weight: bolder;
    color: greenyellow;
    color: rgb(110, 110, 110);
  }
}

@media screen and (min-width: 620px) and (max-width: 920px) {
  .mint-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    max-width: 80vw;
    margin: auto;
    padding: 3em 2em;
    margin-top: 3em;
    border-radius: 15px;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 1);
  }

  .welcome {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 440px;
    margin: auto;
    line-height: 1.7em;
  }

  .head-2 {
    font-size: 1.2em;
  }

  .gif {
    max-width: 80vw;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gif img {
    height: 350px;
    width: 350px;
  }

  .mintbutton-value {
    display: flex;
  }

  .contract-details {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.3em;
  }

  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.gif img {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="number"] {
  position: relative;
  color: rgb(110, 110, 110);
  font-size: 25px;
  font-weight: bold;
}

.contract-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -1.5em;
}

.address {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: bold;
}

.address .blue a {
  color: #013f58;
  font-size: 15px;
}

.mint-infos {
  font-weight: bold;
  color: lightgray;
  margin-top: 2em;
  text-align: center;
}

.modal-btn {
  border: 1px solid black;
  background-color: azure;
  border-radius: 50px;
  padding: 0.5em 1.7em;
  margin-bottom: 1.3em;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin-top: -1em;
}

.modal-btn2 {
  border: 1px solid gold;
  background-color: yellowgreen;
  border-radius: 10px;
  padding: 0.5em 1.7em;
  margin-bottom: 1.3em;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin-top: -1em;
}

.tokenmint {
  position: absolute;
  margin-top: -2em;
  width: 200px;
  height: auto;
  padding: 2em;
  background-color: white;
  border-radius: 7px;
  text-align: center;
  z-index: 999;
  box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
}

.tokenmint .head {
  font-weight: bold;
}

.tokenmint .info {
  margin-top: 1em;
  font-weight: 600;
}

.tokenmint .close {
  width: 50px;
  margin: auto;
  margin-top: 20px;
  padding: 0.4em 0.2em;
  background-color: black;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
}

.modal {
  position: absolute;
  margin-top: -10em;
  margin-left: -3em;
  width: 250px;
  height: 250px;
  padding: 0 1em;
  background-color: white;
  border-radius: 7px;
  z-index: 999;
  box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
}

.modal2 {
  position: absolute;
  margin-top: -10em;
  margin-left: -3em;

  padding: 0 1em;
  background-color: white;
  border-radius: 7px;
  z-index: 999;
  box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 1);
}

.modal-contents .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-contents .head p {
  font-weight: bold;
  font-size: 20px;
}

.wallets {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 1em;
}

.wallets > div {
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .mintsection {
    padding-bottom: 0;
  }

  .mint-container {
    gap: 0;
    max-width: 75vw;
    margin: auto;
  }

  .mint-container > div {
    margin-bottom: 1.5em;
  }

  .modal-btn {
    margin-top: 1.1em;
  }

  .pricedetails {
    margin-top: 1.5em;
  }
}

@media screen and (max-width: 600px) {
  .mint-container {
    gap: 0;
    max-width: 90vw;
    margin: auto;
    padding: 1em 1em;
  }

  .head-2 {
    font-size: 1em;
  }

  .gif img {
    max-width: 50vw;
    margin: auto;
  }

  .mintbutton-value {
    display: flex;
    margin-top: 1em;
  }

  .pricedetails {
    margin-top: 1.5em;
  }

  .contract-details {
    margin-top: 0.1em;
  }

  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .address > div {
    margin-bottom: 1px;
  }

  .reminants {
    padding-top: 3em;
  }
}

@media screen and (max-width: 390px) {
  .contract-details {
    max-width: 60vw;
  }
}

/*.intro-wrapper {
  background: url(../Asset/banner.jpg);
  height: 100vh !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

#pricetag {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: large;
  background-color: rgba(0, 0, 0, 0.356);
}
.intro-blur {
  height: 100vh !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10em;
}

.mint-nft {
  margin-top: 5em;
}

.mint-nft button {
  padding: 1em 6em;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: gold;
  color: black;
  font-weight: bold;
  font-size: 1.1em;
}

@media screen and (min-width: 375px) and (max-width: 540px) {
  .intro-wrapper {
    background: url(../Asset/banner.jpg);
    height: 700px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .intro-blur {
    height: 700px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 15em;
  }

  .mint-nft {
    margin-top: 5em;
  }

  .mint-nft button {
    padding: 1em 6em;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: gold;
    color: black;
    font-weight: bold;
    font-size: 1.1em;
  }
}
*/
