body {
  scroll-behavior: smooth;
  transition: all 0.4s;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: /*rgb(194, 177, 194) palegoldenrod*/ rgb(89, 3, 89);
  color: gold;
  font-weight: bold;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
}

* {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  text-decoration: none;
  /* list-style-type: none; */
  box-sizing: border-box;
}
