.project-container {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 5em;
  height: 100vh;
  position: relative;
}

.project-heading {
  font-size: 2.4em;
  font-weight: bold;
}

.project-wrapper {
  margin-top: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  height: 300px;
}

.projects {
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.8em;
  padding: 1em;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
}

.projects:hover {
  transition: all 1s;
  border: 1px solid white;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .project-container {
    max-width: 410px;
    margin: 0 auto;
    padding-top: 5em;
    height: 150vh;
  }

  .project-heading {
    font-size: 2em;
    font-weight: bold;
  }

  .project-wrapper {
    gap: 2em;
  }
}

@media screen and (min-width: 600px) and (max-width: 920px) {
  .project-container{
    max-width: 700px;
  }
}
