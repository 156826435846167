.faq-container {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 5em;
}

.faq-head {
  font-size: 2.5em;
  font-weight: bold;
}

.faq-accordion {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 5em;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .faq-container {
    max-width: 400px;
  }
  
  .faq-head {
    font-size: 1.7em;
    font-weight: bold;
    margin-left: 3em;
  }

  .faq-accordion {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 5em;
  }

}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .faq-container {
    max-width: 750px;
  }

  .faq-accordion {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 5em;
  }
}
