.navwrapper {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  background-color: white;
}

.navwrapper.active {
  position: fixed;
  top: 0;
  background-color: whitesmoke;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.9em;
}

.logos {
  margin-left: 2em;
}

.logos a {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.logo-picture {
  font-size: 14px;
  font-weight: 900;
  padding: 0.2em;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
}

.logo-name {
  font-size: 15px;
  font-weight: bolder;
}

.react-scroll {
  display: flex;
  align-items: center;
  gap: 3em;
}

.react-scroll a {
  font-size: 14px;
  font-weight: bold;
  transition: all 0.4s;
  cursor: pointer;
}

.react-scroll a:hover {
  color: rgb(207, 7, 207);
}

.mint-directory {
  margin-right: 2em;
  border-radius: 10px;
  color: red;
}

.mint-directory a {
  font-size: 14px;
  padding: 0.6em 1.3em;
  background-color: gold;
  font-weight: bold;
  color: rebeccapurple;
  transition: all 0.4s;
}

.mint-directory a:hover {
  box-shadow: 0 1px 15px 3px gold;
}

.switch {
  font-size: 14px;
  padding: 0.6em 1.3em;
  background-color: gold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s;
}

@media screen and (max-width: 600px) {
  .react-scroll {
    display: none;
  }

  .logo-name {
    font-size: 22px;
  }

  .logos {
    margin-left: 1em;
  }

  .mint-directory {
    margin-right: 1em;
  }
}

@media screen and (min-width: 601px) and (max-width: 920px) {
  .react-scroll {
    display: none;
  }

  .logo-name {
    font-size: 22px;
  }

  .logos {
    margin-left: 1em;
  }

  .mint-directory {
    margin-right: 1em;
  }
}
