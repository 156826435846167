.intro-wrapper {
  height: 100vh !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.intro-blur {
  background: url(../Asset/banner.jpg);
  background-blend-mode: soft-light;
  height: 100vh !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.intro-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  max-width: 550px;
  padding-top: 7em;
  padding-right: 5em;
  float: right;
  color: white;
}

.intro-heading {
  padding-top: 1em;
  font-size: 2.8em;
  text-align: left;
  font-weight: bolder;
  line-height: 1.1em;
  text-shadow: 6px 2px 5px #ffffffb2;
}

.intro-description {
  margin-top: 1em;
  font-size: 20px;
  font-weight: bold;
}

.intro-mint-link {
  margin-top: 2em;
}

.intro-mint-link a {
  background-color: gold;
  color: black;
  font-weight: bold;
  padding: 0.5em 1em;
  font-size: 1.1em;
  border-radius: 5px;
  transition: all 0.4s;
}

.intro-mint-link a:hover {
  box-shadow: 0 1px 15px 3px gold;
}

@media screen and (min-width: 350px) and (max-width: 540px) {
  .intro-wrapper {
    background: url(../Asset/banner.jpg);
    height: 700px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .intro-blur {
    height: 700px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .intro-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 550px;
    padding-top: 9em;
    padding-right: 0;
  }

  .intro-heading {
    font-size: 2.5em;
    text-align: center;
  }
  .intro-mint-link {
    margin-top: 3em;
  }

  .intro-mint-link a {
    background-color: gold;
    padding: 0.5em 1em;
    font-size: 1.2em;
    transition: all 0.4s;
  }

  .intro-mint-link a:hover {
    box-shadow: 0 1px 15px 3px gold;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .intro-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 750px;
    padding-top: 9em;
    padding-right: 0;
    float: none;
    margin: 0 auto;
  }

  .intro-heading {
    text-align: center;
  }
}

@media screen and (min-width: 780px) and (max-width: 920px) {
  .intro-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    max-width: 750px;
    padding-top: 9em;
    padding-right: 0;
    float: none;
    margin: 0 auto;
  }

  .intro-heading {
    text-align: center;
  }
}
